import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import axios from 'axios';
import { baseUrl, headers } from './lookups';

const APIContext = createContext(null);

export function APIContextProvider({ children }) {
  const [trusts, setTrusts] = useState([]);
  const [leads, setLeads] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [cipCategories, setCipCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mainAlternativeCategories, setMainAlternativeCategories] = useState(
    [],
  );
  const [mainCpvCategories, setMainCpvCategories] = useState([]);
  const [existingSuppliers, setExistingSuppliers] = useState([]);

  const ics = sessionStorage.getItem('ics');

  const fetchAllProjects = useCallback(async () => {
    try {
      const pageSize = 200;
      const firstPageResponse = await axios.get(
        `${baseUrl}/workplan/projects?ics=${ics}&page=1&pageSize=${pageSize}`,
        { headers },
      );
      const { totalRecords } = firstPageResponse.data;
      const totalPages = Math.ceil(totalRecords / pageSize);
      const promises = [];

      for (let page = 1; page <= totalPages; page += 1) {
        promises.push(
          axios.get(
            `${baseUrl}/workplan/projects?ics=${ics}&page=${page}&pageSize=${pageSize}`,
            { headers },
          ),
        );
      }

      const results = await Promise.all(promises);
      const allProjects = results.reduce(
        (acc, result) => [...acc, ...result.data.projects],
        [],
      );

      setProjects(allProjects);
    } catch (error) {
      // pass
    }
  }, [ics]); // Ensure ics is included in the dependency array

  const searchSuppliers = useCallback(async (searchQuery) => {
    try {
      const response = await axios.get(
        `${baseUrl}/workplan/suppliers?&search=${searchQuery}`,
        { headers },
      );
      const suppliers = response.data.items;
      return suppliers.map((supplier) => ({
        id: supplier.atamis_id,
        name: supplier.name,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSupplierById = useCallback(async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/workplan/suppliers/supplier_atamis_id=${id}`,
        {
          headers,
        },
      );
      return {
        id: response.data.atamis_id,
        name: response.data.name,
      };
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/workplan/trusts?ics=${ics}`, { headers })
      .then((response) => {
        setTrusts(response.data);
      });
    axios
      .get(`${baseUrl}/workplan/leads?ics=${ics}`, { headers })
      .then((response) => {
        setLeads(response.data);
      });
    axios
      .get(`${baseUrl}/workplan/divisions?ics=${ics}`, { headers })
      .then((response) => {
        setDivisions(response.data);
      });
    axios
      .get(`${baseUrl}/workplan/cip-categories?ics=${ics}`, { headers })
      .then((response) => {
        setCipCategories(response.data);
      });

    const fetchProjects = async () => {
      try {
        const pageSize = 200;
        const firstPageResponse = await axios.get(
          `${baseUrl}/workplan/projects?ics=${ics}&page=1&pageSize=${pageSize}`,
          { headers },
        );
        const { totalRecords } = firstPageResponse.data;
        const totalPages = Math.ceil(totalRecords / pageSize);
        const promises = [];

        for (let page = 1; page <= totalPages; page += 1) {
          promises.push(
            axios.get(
              `${baseUrl}/workplan/projects?ics=${ics}&page=${page}&pageSize=${pageSize}`,
              { headers },
            ),
          );
        }

        const results = await Promise.all(promises);
        const allProjects = results.reduce(
          (acc, result) => [...acc, ...result.data.projects],
          [],
        );

        setProjects(allProjects);
      } catch (error) {
        // pass
      }
    };

    fetchProjects();
    axios
      .get(`${baseUrl}/workplan/main-alternative-categories?ics=${ics}`, {
        headers,
      })
      .then((response) => {
        const mainAltCategoriesResponse = response.data.map(
          (mainAltCategory) => ({
            id: mainAltCategory.atamis_id,
            name: mainAltCategory.name,
          }),
        );
        setMainAlternativeCategories(mainAltCategoriesResponse);
      });
    axios
      .get(`${baseUrl}/workplan/main-cpv-categories?ics=${ics}`, {
        headers,
      })
      .then((response) => {
        const mainCpvCategoriesResponse = response.data.map(
          (mainCpvCategory) => ({
            id: mainCpvCategory.atamis_id,
            name: mainCpvCategory.name,
          }),
        );
        setMainCpvCategories(mainCpvCategoriesResponse);
      });
    axios
      .get(`${baseUrl}/workplan/suppliers?`, {
        headers,
      })
      .then((response) => {
        const suppliers = response.data.items;
        const suppliersResponse = suppliers.map((supplier) => ({
          id: supplier.atamis_id,
          name: supplier.name,
        }));
        setExistingSuppliers(suppliersResponse);
      });
  }, [ics]);

  const apiContextValues = useMemo(
    () => ({
      trusts,
      leads,
      divisions,
      cipCategories,
      projects,
      mainAlternativeCategories,
      mainCpvCategories,
      existingSuppliers,
      setProjects,
      fetchAllProjects,
      searchSuppliers,
      getSupplierById,
    }),
    [
      trusts,
      leads,
      divisions,
      cipCategories,
      projects,
      mainAlternativeCategories,
      mainCpvCategories,
      existingSuppliers,
      fetchAllProjects,
      searchSuppliers,
      getSupplierById,
    ],
  );

  return (
    <APIContext.Provider
      // Add required values to the value prop within an object (my preference)
      value={apiContextValues}>
      {children}
    </APIContext.Provider>
  );
}

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
