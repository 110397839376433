import React, { useRef, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import * as Label from '@radix-ui/react-label';
import { NumericFormat } from 'react-number-format';
import * as Checkbox from '@radix-ui/react-checkbox';
import { HiCheck } from 'react-icons/hi';
import ComboBox from './ComboBox';
import { useAPI } from '../../app/apiContext';
import * as lookups from '../../app/lookups';
import MonthlyBreakdown from './MonthlyBreakdown';
import SingleDateField from './SingleDateField';
import './Form.css';
import SelectErrorMessage from '../SelectErrorMessage';

export default function Form() {
  const {
    register,
    formState: { errors, isSubmitted },
    reset,
    resetField,
    watch,
    setValue,
    trigger,
    control,
    getValues,
  } = useFormContext();

  const {
    trusts,
    leads,
    divisions,
    cipCategories,
    mainAlternativeCategories,
    mainCpvCategories,
    existingSuppliers,
    searchSuppliers,
    getSupplierById,
  } = useAPI();
  const formData = {
    trusts,
    leads,
    divisions,
    cipCategories,
    status: lookups.status,
    commercialStrategy: lookups.commercialStrategy,
    routeToMarket: lookups.routeToMarket,
    cmClassification: lookups.cmClassification,
    savingsTypes: lookups.savingsTypes,
    savingsCategories: lookups.savingsCategories,
    procurementRoutes: lookups.procurementRoutes,
    contractTypes: lookups.contractTypes,
    publicProcurementRegulations: lookups.publicProcurementRegulations,
    cyberSecurityCertifications: lookups.cyberSecurityCertifications,
    cyberSecurityDecisionRationales: lookups.cyberSecurityDecisionRationales,
  };

  const ref = useRef();

  const [financialYear, setFinancialYear] = useState(watch('financialYear'));

  const getFinancialYear = (dateString) => {
    if (dateString) {
      // eslint-disable-next-line prefer-const
      let [day, month, year] = dateString.split('/').map(Number);
      const date = new Date(year, month - 1, day);

      year = date.getFullYear();
      month = date.getMonth() + 1;

      if (month < 4) {
        year -= 1;
      }
      const financialYearString = `${year % 100}/${(year + 1) % 100}`;
      return financialYearString;
    }
    return dateString;
  };

  const status = useWatch({
    control,
    name: 'status',
  });

  const anticipatedDelivery = useWatch({
    control,
    name: 'anticipatedDelivery',
  });

  const actualDelivery = useWatch({
    control,
    name: 'actualDelivery',
  });

  const commercialStrategy = useWatch({
    control,
    name: 'commercialStrategy',
  });

  // Helper function to check if a field should be required based on status
  const isNonPotentialStatus = (currentStatus) =>
    ['Delivered', 'Blocked'].includes(currentStatus);

  const isExistingContractStrategy = (strategy) =>
    [
      '2. Re-procurement (same scope)',
      '3. Re-procurement (different scope)',
      '4. Extension (in contract)',
      '5. Contract variation',
    ].includes(strategy);

  const requiresCyberSecurity = (strategy) =>
    [
      '1. New requirement',
      '2. Re-procurement (same scope)',
      '3. Re-procurement (different scope)',
    ].includes(strategy);

  const isExistingContractFieldMandatory =
    isNonPotentialStatus(status) &&
    isExistingContractStrategy(commercialStrategy);

  const isCyberSecurityFieldMandatory =
    isNonPotentialStatus(status) && requiresCyberSecurity(commercialStrategy);

  useEffect(() => {
    const lowerCaseStatus = status.toLowerCase();
    if (
      lowerCaseStatus === 'potential' ||
      lowerCaseStatus === 'planned' ||
      lowerCaseStatus === 'blocked'
    ) {
      setFinancialYear(getFinancialYear(anticipatedDelivery));
      setValue('financialYear', financialYear);
    } else if (lowerCaseStatus === 'delivered') {
      setFinancialYear(getFinancialYear(actualDelivery));
      setValue('financialYear', financialYear);
    }
  }, [status, anticipatedDelivery, actualDelivery, setValue, financialYear]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'trust') {
        resetField('division', {
          defaultValue: '',
        });

        trigger('division');
      }
      if (name === 'cyberSecurityCertification') {
        resetField('cyberSecurityDecisionRationale', {
          defaultValue: '',
        });

        trigger('cyberSecurityDecisionRationale');
      }
      if (name === 'savingsType') {
        resetField('savingsCategory', {
          defaultValue: '',
        });

        trigger('savingsCategory');
      }
      if (name === 'publicProcurementRegulations') {
        resetField('procurementRoute', {
          defaultValue: '',
        });

        trigger('procurementRoute');
      }
    });

    // Cleanup subscription on component unmount
    return () => subscription.unsubscribe();
  }, [watch, resetField, trigger]);

  const width = {
    1: 'w-maxFull',
    2: 'w-[24rem]',
    3: 'w-[16rem]',
    4: 'w-[12rem]',
  };

  return (
    <div>
      <div id="row1" className="pb-2">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Workplan Name<span>*</span>
              </Label.Root>
              {errors.title && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('title') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['1']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="title"
              type="text"
              placeholder="Workplan Name"
              value={watch('title')}
              {...register('title', {
                required: true,
              })}
            />
          </div>
        </div>
      </div>

      <div id="row2" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Contracting Authority<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="trust"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              ref={ref}
              width={width[2]}
              value={
                trusts.find((trust) => trust.id === getValues('trust'))?.name
              }
              options={trusts}
              fieldId="trust"
              control={control}
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              resetField={resetField}
              rowHeight={40}
              listWidth={250}
              mandatory
              disabled={false}
              childField="division"
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Division<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="division"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[2]}
              control={control}
              value={
                divisions.find(
                  (division) => division.id === getValues('division'),
                )?.name
              }
              options={divisions.filter((o) => o.trust === watch('trust'))}
              fieldId="division"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              mandatory
              disabled={watch('trust') === ''}
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row3" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Department<span>*</span>
              </Label.Root>
              {errors.dept && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('dept') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['3']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="dept"
              type="text"
              placeholder="Department"
              value={watch('dept')}
              {...register('dept', {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                External Workplan ID<span>*</span>
              </Label.Root>
              {errors.projectRef && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('projectRef') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['3']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="projectRef"
              type="text"
              placeholder="External Workplan ID"
              value={watch('projectRef')}
              {...register('projectRef', {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Commercial Lead<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="projectLead"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              value={
                leads.find(
                  (projectLead) => projectLead.id === getValues('projectLead'),
                )?.name
              }
              options={leads}
              disabled={false}
              fieldId="projectLead"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              mandatory
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row4" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                CIP Category<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="cipCategory"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              control={control}
              value={
                cipCategories.find(
                  (cipCategory) => cipCategory.id === getValues('cipCategory'),
                )?.name
              }
              options={cipCategories}
              disabled={false}
              fieldId="cipCategory"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              mandatory
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Savings Type
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="savingsType"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              control={control}
              value={
                formData.savingsTypes.find(
                  (savingsTypeItem) =>
                    savingsTypeItem.id === getValues('savingsType'),
                )?.name
              }
              options={formData.savingsTypes}
              disabled={false}
              fieldId="savingsType"
              setValue={setValue}
              resetField={resetField}
              trigger={trigger}
              reset={reset}
              watch={watch}
              childField="savingsCategory"
            />
          </div>
        </div>{' '}
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Savings Category
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="savingsCategory"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              control={control}
              value={
                formData.savingsCategories.find(
                  (savingCategory) =>
                    savingCategory.id === getValues('savingsCategory'),
                )?.name
              }
              options={formData.savingsCategories.filter(
                (o) => o.savings_type === watch('savingsType'),
              )}
              fieldId="savingsCategory"
              setValue={setValue}
              trigger={trigger}
              disabled={watch('savingsType') === ''}
              reset={reset}
              watch={watch}
              childField={null}
            />
          </div>
        </div>
      </div>

      <div id="row5" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Status<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="status"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              value={getValues('status')}
              options={formData.status}
              disabled={false}
              fieldId="status"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              mandatory
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Savings (£)<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="cipTarget"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <NumericFormat
              getInputRef={ref}
              disabled={false}
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border $  ${width[3]} bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="cip_target"
              thousandSeparator=","
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={watch('cipTarget') / 100}
              displayType="input"
              onValueChange={(values) =>
                setValue('cipTarget', Number(values.value) * 100)
              }
              {...register('cipTarget', { required: true })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Commercial Strategy<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="commercialStrategy"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={55}
              listWidth={300}
              ref={ref}
              width={width[3]}
              value={getValues('commercialStrategy')}
              options={formData.commercialStrategy}
              disabled={false}
              fieldId="commercialStrategy"
              setValue={setValue}
              trigger={trigger}
              mandatory
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
      </div>

      <div id="row6" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Procurement Start Date<span>*</span>
              </Label.Root>
              {errors.startDate && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  A selection is required
                </div>
              )}
            </div>
            <SingleDateField
              ref={ref}
              disabled={false}
              mandatory
              width={width[3]}
              fieldId="startDate"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Planned Savings Start Date
                {(status === 'Potential' || status === 'Planned') && (
                  <span>*</span>
                )}
              </Label.Root>
              {errors.anticipatedDelivery && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  A selection is required
                </div>
              )}
            </div>
            <SingleDateField
              ref={ref}
              mandatory={status === 'Potential' || status === 'Planned'}
              disabled={status === 'Delivered'}
              width={width[3]}
              fieldId="anticipatedDelivery"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Financial Year
              </Label.Root>
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('financialYear') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['3']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="financialYear"
              type="text"
              disabled
              placeholder="Financial Year"
              value={financialYear}
              {...register('financialYear', {
                required: false,
              })}
            />
          </div>
        </div>
      </div>

      <div id="row7" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Actual Delivery
              </Label.Root>
              {errors.actualDelivery && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  A selection is required
                </div>
              )}
            </div>
            <SingleDateField
              ref={ref}
              mandatory={status === 'Delivered'}
              disabled={status !== 'Delivered'}
              width={width[3]}
              fieldId="actualDelivery"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Total Contract Value (£)<span>*</span>
              </Label.Root>
              {errors.contractValue && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  A selection is required
                </div>
              )}
            </div>
            <NumericFormat
              getInputRef={ref}
              disabled={false}
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('contractValue') === 0
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width[3]
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              thousandSeparator=","
              prefix="£"
              id="contractValue"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={watch('contractValue') / 100}
              displayType="input"
              onValueChange={(values) =>
                setValue('contractValue', Number(values.value) * 100)
              }
              {...register('contractValue', {
                required: true,
                validate: (value) => value !== 0 || 'Value cannot be zero',
              })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                CM Classification<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="cmClassification"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={40}
              listWidth={250}
              ref={ref}
              width={width[3]}
              value={getValues('cmClassification')}
              options={formData.cmClassification}
              disabled={false}
              fieldId="cmClassification"
              setValue={setValue}
              trigger={trigger}
              mandatory
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row8" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <Controller
              control={control}
              name="savingsByMonth"
              render={({ field }) => (
                <MonthlyBreakdown
                  {...field}
                  label="Monthly Breakdown (£)"
                  mandatory={false}
                  ref={ref}
                  fieldId="savingsByMonth"
                  watch={watch}
                  setValue={setValue}
                  control={control}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div id="row9" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                CIP Tracking Ref
              </Label.Root>
              {errors.cipRef && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('cipRef') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['2']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              type="text"
              placeholder="CIP Tracking Ref"
              disabled={watch('status') !== 'Delivered'}
              value={watch('cipRef')}
              {...register('cipRef', {
                required: watch('status') === 'Delivered',
              })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                CIP Approved By
              </Label.Root>
              {errors.approvedBy && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <input
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('approvedBy') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width['2']
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              type="text"
              placeholder="CIP Approved By"
              disabled={watch('status') !== 'Delivered'}
              value={watch('approvedBy')}
              {...register('approvedBy', {
                required: watch('status') === 'Delivered',
              })}
            />
          </div>
        </div>
      </div>
      <div id="row10" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Main Alternative Category<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="mainAlternativeCategory"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              value={
                mainAlternativeCategories.find(
                  (mainAlternativeCategory) =>
                    mainAlternativeCategory.id ===
                    getValues('mainAlternativeCategory'),
                )?.name
              }
              options={mainAlternativeCategories}
              disabled={false}
              fieldId="mainAlternativeCategory"
              setValue={setValue}
              trigger={trigger}
              mandatory
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Main CPV Category<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="mainCpvCategory"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              // value={getValues('mainCpvCategories')}
              value={
                mainCpvCategories.find(
                  (mainCpvCategory) =>
                    mainCpvCategory.id === getValues('mainCpvCategory'),
                )?.name
              }
              options={mainCpvCategories}
              disabled={false}
              fieldId="mainCpvCategory"
              setValue={setValue}
              trigger={trigger}
              mandatory
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row11" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Route to Market<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="routeToMarket"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              mandatory
              value={getValues('routeToMarket')}
              options={formData.routeToMarket}
              disabled={false}
              fieldId="routeToMarket"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Contract Type<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="contractType"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={50}
              listWidth={300}
              ref={ref}
              width={width[2]}
              mandatory
              value={getValues('contractType')}
              options={formData.contractTypes}
              disabled={false}
              fieldId="contractType"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row12" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Public Procurement Regulations<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="publicProcurementRegulations"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              value={getValues('publicProcurementRegulations')}
              options={formData.publicProcurementRegulations}
              disabled={false}
              fieldId="publicProcurementRegulations"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              mandatory
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Procurement Route<span>*</span>
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="procurementRoute"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              value={getValues('procurementRoute')}
              options={formData.procurementRoutes.filter(
                (route) =>
                  route.publicProcurementRegulations ===
                  watch('publicProcurementRegulations'),
              )}
              disabled={
                watch('publicProcurementRegulations') === '' ||
                watch('publicProcurementRegulations') === null
              }
              fieldId="procurementRoute"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
              mandatory
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row13" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Contract Duration (months)
              </Label.Root>
              {errors.contractDuration && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <NumericFormat
              getInputRef={ref}
              disabled={false}
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border 
               ${width[3]}
               bg-white text-xs font-medium text-platform-primary-grey-800 border-platform-primary-grey-200 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="contractDuration"
              decimalScale={0}
              defaultValue={0}
              value={watch('contractDuration')}
              displayType="input"
              onValueChange={(values) =>
                setValue('contractDuration', values.value)
              }
              {...register('contractDuration', { required: false })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Extension Length (months)
              </Label.Root>
              {errors.estimatedExtensionLength && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  Something wrong in this field
                </div>
              )}
            </div>
            <NumericFormat
              getInputRef={ref}
              disabled={false}
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border 
               ${width[3]}
               bg-white text-xs font-medium text-platform-primary-grey-800 border-platform-primary-grey-200 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="estimatedExtensionLength"
              decimalScale={0}
              defaultValue={0}
              value={watch('estimatedExtensionLength')}
              displayType="input"
              onValueChange={(values) =>
                setValue('estimatedExtensionLength', values.value)
              }
              {...register('estimatedExtensionLength', { required: false })}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Extension Value (£)
              </Label.Root>
              {errors.estimatedExtensionValue && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  Something wrong in this field
                </div>
              )}
            </div>
            <NumericFormat
              getInputRef={ref}
              disabled={false}
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border
               ${width[3]}
               bg-white text-xs font-medium text-platform-primary-grey-800 border-platform-primary-grey-200 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="estimatedExtensionValue"
              thousandSeparator=","
              prefix="£"
              decimalScale={2}
              fixedDecimalScale
              defaultValue={0}
              value={watch('estimatedExtensionValue') / 100}
              displayType="input"
              onValueChange={(values) =>
                setValue('estimatedExtensionValue', Number(values.value) * 100)
              }
              {...register('estimatedExtensionValue', { required: false })}
            />
          </div>
        </div>
      </div>
      <div id="row14" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Existing Supplier{' '}
                {isExistingContractFieldMandatory ?? <span>*</span>}
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="existingSupplier"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={70}
              listWidth={300}
              ref={ref}
              width={width[2]}
              value={
                existingSuppliers.find(
                  (existingSupplier) =>
                    existingSupplier.id === getValues('existingSupplier'),
                )?.name
              }
              options={existingSuppliers}
              searchFunction={searchSuppliers}
              getInitialValue={getSupplierById}
              disabled={false}
              fieldId="existingSupplier"
              setValue={setValue}
              trigger={trigger}
              mandatory={isExistingContractFieldMandatory}
              reset={reset}
              watch={watch}
              control={control}
              childField={null}
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Existing Contract End Date
                {isExistingContractFieldMandatory ?? <span>*</span>}
              </Label.Root>
              {errors.existingContractEndDate && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  A selection is required
                </div>
              )}
            </div>
            <SingleDateField
              ref={ref}
              disabled={false}
              mandatory={isExistingContractFieldMandatory}
              width={width[3]}
              fieldId="existingContractEndDate"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              control={control}
            />
          </div>
        </div>
      </div>
      <div id="row15" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Cyber Security Certification
                {isCyberSecurityFieldMandatory ?? <span>*</span>}
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="cyberSecurityCertification"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              ref={ref}
              width={width[2]}
              value={
                formData.cyberSecurityCertifications.find(
                  (cyberSecurityCertification) =>
                    cyberSecurityCertification.id ===
                    getValues('cyberSecurityCertification'),
                )?.name
              }
              options={formData.cyberSecurityCertifications}
              fieldId="cyberSecurityCertification"
              control={control}
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              resetField={resetField}
              rowHeight={40}
              listWidth={250}
              mandatory={isCyberSecurityFieldMandatory}
              disabled={false}
              childField="cyberSecurityDecisionRationale"
            />
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Rationale for Cyber Security Decision
                {isCyberSecurityFieldMandatory ?? <span>*</span>}
              </Label.Root>
              <SelectErrorMessage
                errors={errors}
                fieldId="cyberSecurityDecisionRationale"
                useWatch={useWatch}
                isSubmitted={isSubmitted}
                control={control}
              />
            </div>
            <ComboBox
              rowHeight={78}
              listWidth={250}
              ref={ref}
              width={width[2]}
              control={control}
              value={
                formData.cyberSecurityDecisionRationales.find(
                  (cyberSecurityDecisionRationale) =>
                    cyberSecurityDecisionRationale.id ===
                    getValues('cyberSecurityDecisionRationale'),
                )?.name
              }
              options={formData.cyberSecurityDecisionRationales.filter((o) =>
                o.cyberSecurityCertification.includes(
                  watch('cyberSecurityCertification'),
                ),
              )}
              fieldId="cyberSecurityDecisionRationale"
              setValue={setValue}
              trigger={trigger}
              reset={reset}
              watch={watch}
              mandatory={isCyberSecurityFieldMandatory}
              disabled={watch('cyberSecurityCertification') === ''}
              childField={null}
            />
          </div>
        </div>
      </div>
      <div id="row16" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3 w-maxFull">
          <div>
            <div className="flex justify-between">
              <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                Commercial Notes (Long)<span>*</span>
              </Label.Root>
              {errors.notes && (
                <div className="text-xs font-bold text-platform-primary-red-800 ">
                  This field is required
                </div>
              )}
            </div>
            <textarea
              className={`appearance-none block py-3 px-4 leading-tight rounded-md border ${
                watch('notes') === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              }  ${
                width[1]
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0 disabled:bg-platform-ainc-grey-400 disabled:border-platform-primary-grey-200`}
              id="notes"
              cols={100}
              rows={5}
              placeholder="Commercial Notes (Long)"
              value={watch('notes')}
              onChange={(e) =>
                setValue('notes', e.target.value.replace(/"/g, "'"))
              }
              {...register('notes', {
                required: true,
              })}
            />
          </div>
        </div>
      </div>
      <div id="row17" className="pb-2 flex justify-between">
        <div className="mx-2 mt-2 mb-3">
          <div className={`flex justify-start ${width[3]}`}>
            <div className="ml-4">
              <div className="flex justify-between">
                <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                  Recurrent?<span>*</span>
                </Label.Root>
                {errors.isRecurrent && (
                  <div className="text-xs font-bold text-platform-primary-red-800 ">
                    A selection is required
                  </div>
                )}
              </div>
              <Controller
                control={control}
                name="isRecurrent"
                rules={{
                  validate: (value) => {
                    if (value === null) {
                      return 'A selection is required';
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <div className="flex items-center mt-2">
                    <Checkbox.Root
                      {...field}
                      className="flex h-[24px] w-[24px] appearance-none items-center justify-center rounded-md border border-platform-primary-orange-800 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                      id="is_recurrent-true"
                      checked={watch('isRecurrent') === true}
                      onCheckedChange={() => {
                        setValue('isRecurrent', true);
                        trigger('isRecurrent');
                      }}>
                      <Checkbox.Indicator className="flex justify-center">
                        <HiCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <Label.Root htmlFor="is_recurrent-true" className="m-1">
                      Yes
                    </Label.Root>
                    <Checkbox.Root
                      {...field}
                      className="flex h-[24px] w-[24px] appearance-none items-center justify-center rounded-md border border-platform-primary-orange-800 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                      id="is_recurrent-false"
                      checked={watch('isRecurrent') === false}
                      onCheckedChange={() => {
                        setValue('isRecurrent', false);
                        trigger('isRecurrent');
                      }}>
                      <Checkbox.Indicator className="flex justify-center">
                        <HiCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <Label.Root htmlFor="is_recurrent-false" className="m-1">
                      No
                    </Label.Root>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className="mx-2 mt-2 mb-3">
          <div className={`flex justify-start ${width[3]}`}>
            <div className="ml-4">
              <div className="flex justify-between">
                <Label.Root className="block uppercase tracking-wide text-platform-primary-grey-800 text-xs font-bold">
                  AdviseInc service used?<span>*</span>
                </Label.Root>
                {errors.usedPd && (
                  <div className="text-xs font-bold text-platform-primary-red-800 ">
                    A selection is required
                  </div>
                )}
              </div>
              <Controller
                control={control}
                name="usedPd"
                rules={{
                  validate: (value) => {
                    if (value === null) {
                      return 'A selection is required';
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <div className="flex items-center mt-2">
                    <Checkbox.Root
                      {...field}
                      className="flex h-[24px] w-[24px] appearance-none items-center justify-center rounded-md border border-platform-primary-orange-800 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                      id="used_pd-true"
                      checked={watch('usedPd') === true}
                      onCheckedChange={() => {
                        setValue('usedPd', true);
                        trigger('usedPd');
                      }}>
                      <Checkbox.Indicator className="flex justify-center">
                        <HiCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <Label.Root htmlFor="used_pd-true" className="m-1">
                      Yes
                    </Label.Root>
                    <Checkbox.Root
                      {...field}
                      className="flex h-[24px] w-[24px] appearance-none items-center justify-center rounded-md border border-platform-primary-orange-800 text-white focus:ring-0 data-[state=checked]:border-platform-primary-orange-800 data-[state=checked]:bg-platform-primary-orange-800"
                      id="used_pd-false"
                      checked={watch('usedPd') === false}
                      onCheckedChange={() => {
                        setValue('usedPd', false);
                        trigger('usedPd');
                      }}>
                      <Checkbox.Indicator className="flex justify-center">
                        <HiCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <Label.Root htmlFor="used_pd-false" className="m-1">
                      No
                    </Label.Root>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
