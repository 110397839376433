import React, { forwardRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { HiX } from 'react-icons/hi';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { format, parse } from 'date-fns';
import { baseUrl, headers } from '../../app/lookups';
import AlertMessage from '../../components/AlertMessage';
import { useAPI } from '../../app/apiContext';
import Form from '../../components/dynamic-form/Form';

const DuplicateProjectModal = forwardRef(({ setShowModal, project }, ref) => {
  const [duplicateProjectResponse, setDuplicateProjectResponse] =
    useState(null);
  const [saveFieldsOnSubmit, setSaveFieldsOnSubmit] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [atamisDisplayAlert, setAtamisDisplayAlert] = useState(false);

  const { fetchAllProjects } = useAPI();

  const ics = sessionStorage.getItem('ics');
  const form = useForm({
    defaultValues: {
      title: project.title,
      trust: project.trust,
      division: project.division,
      dept: project.dept,
      projectRef: project.project_ref,
      cipCategory: project.cip_category,
      projectLead: project.project_lead,
      status: project.status,
      cipTarget: project.cip_target,
      financialYear: project.financial_year,
      startDate:
        project.start_date === null
          ? null
          : format(
              parse(project.start_date, 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy',
            ),
      anticipatedDelivery:
        project.anticipated_delivery === null
          ? null
          : format(
              parse(project.anticipated_delivery, 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy',
            ),
      isRecurrent: project.is_recurrent,
      actualDelivery:
        project.actual_delivery === null
          ? null
          : format(
              parse(project.actual_delivery, 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy',
            ),
      contractValue: project.contract_value,
      usedPd: project.used_pd,
      savingsByMonth: project.savings_by_month,
      cipRef: project.cip_ref,
      approvedBy: project.approved_by,
      notes: project.notes,
      inYearSaving: project.current_year_saving,
      carryOver: project.next_year_saving,
      commercialStrategy: project.commercial_strategy,
      routeToMarket: project.route_to_market,
      cmClassification: project.cm_classification,
      mainCpvCategory: project.main_cpv_category,
      mainAlternativeCategory: project.main_alternative_category,
      contractDuration: project.contract_duration,
      estimatedExtensionLength: project.estimated_extension_length,
      estimatedExtensionValue: project.estimated_extension_value,
      savingsType: project.savings_type,
      savingsCategory: project.savings_category,
      publicProcurementRegulations: project.public_procurement_regulations,
      procurementRoute: project.procurement_route,
      contractType: project.contract_type,
      existingSupplier: project.existing_supplier,
      existingContractEndDate:
        project.existing_contract_end_date === null
          ? null
          : format(
              parse(
                project.existing_contract_end_date,
                'yyyy-MM-dd',
                new Date(),
              ),
              'dd/MM/yyyy',
            ),
      cyberSecurityCertification: project.cyber_security_certification,
      cyberSecurityDecisionRationale: project.cyber_security_rationale,
    },
  });

  const handleClearForm = () => {
    form.reset({
      title: '',
      trust: '',
      division: '',
      dept: '',
      projectRef: '',
      cipCategory: '',
      projectLead: '',
      status: '',
      cipTarget: 0,
      financialYear: '',
      startDate: '',
      anticipatedDelivery: '',
      isRecurrent: null,
      actualDelivery: '',
      contractValue: 0,
      usedPd: null,
      savingsByMonth: [],
      cipRef: '',
      approvedBy: '',
      notes: '',
      inYearSaving: 0,
      carryOver: 0,
      commercialStrategy: '',
      routeToMarket: '',
      cmClassification: '',
      mainCpvCategory: '',
      mainAlternativeCategory: '',
      contractDuration: 0,
      estimatedExtensionLength: 0,
      estimatedExtensionValue: 0,
      savingsType: '',
      savingsCategory: '',
      publicProcurementRegulations: '',
      procurementRoute: '',
      contractType: '',
      existingSupplier: '',
      existingContractEndDate: null,
      cyberSecurityCertification: '',
      cyberSecurityDecisionRationale: '',
    });
    setSaveFieldsOnSubmit(false);
  };

  const onSubmit = async (data) => {
    const responseData = await axios.post(
      `${baseUrl}/workplan/project`,
      {
        title: data.title,
        trust: data.trust,
        division: data.division,
        dept: data.dept,
        project_ref: data.projectRef,
        cip_category: data.cipCategory,
        project_lead: data.projectLead,
        status: data.status,
        cip_target: data.cipTarget,
        financial_year: data.financialYear,
        start_date:
          data.startDate === null
            ? null
            : format(
                parse(data.startDate, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd',
              ),
        anticipated_delivery:
          data.anticipatedDelivery === null
            ? null
            : format(
                parse(data.anticipatedDelivery, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd',
              ),
        is_recurrent: data.isRecurrent,
        actual_delivery:
          data.actualDelivery === null
            ? null
            : format(
                parse(data.actualDelivery, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd',
              ),
        contract_value: data.contractValue,
        used_pd: data.usedPd,
        savings_by_month: data.savingsByMonth,
        cip_ref: data.cipRef,
        approved_by: data.approvedBy,
        notes: data.notes,
        current_year_saving: data.inYearSaving,
        next_year_saving: data.carryOver,
        ics,
        action: 'CREATE',
        updated_by: sessionStorage.getItem('name'),
        date_updated: new Date(),
        savings_breakdown:
          'savings_by_month' in form
            ? JSON.stringify(form.savings_by_month)
            : '',
        commercial_strategy: data.commercialStrategy,
        route_to_market: data.routeToMarket,
        cm_classification: data.cmClassification,
        main_cpv_category: data.mainCpvCategory,
        main_alternative_category: data.mainAlternativeCategory,
        contract_duration: data.contractDuration,
        estimated_extension_length: data.estimatedExtensionLength,
        estimated_extension_value: data.estimatedExtensionValue,
        savings_type: data.savingsType,
        savings_category: data.savingsCategory,
        public_procurement_regulations: data.publicProcurementRegulations,
        procurement_route: data.procurementRoute,
        contract_type: data.contractType,
        existing_supplier: data.existingSupplier,
        existing_contract_end_date:
          data.existingContractEndDate === null
            ? null
            : format(
                parse(data.existingContractEndDate, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd',
              ),

        cyber_security_certification: data.cyberSecurityCertification,
        cyber_security_rationale: data.cyberSecurityDecisionRationale,
      },
      { headers },
    );
    fetchAllProjects();
    setDuplicateProjectResponse(responseData.data);
    setDisplayAlert(true);
    setAtamisDisplayAlert(true);
    if (!saveFieldsOnSubmit && duplicateProjectResponse) {
      handleClearForm();
    }
  };

  const onError = () => {
    // eslint-disable-next-line no-console
    console.log('Failure');
  };

  return (
    <div ref={ref}>
      <Dialog.Content className="w-[60vw] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="flex justify-center items-center">
          <div className="flex max-w-5xl p-2">
            <div className="h-[90vh] overflow-y-scroll rounded-lg shadow-lg flex flex-col w-full bg-white border border-platform-primary-grey-200">
              <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
                <div className="text-3xl font-bold text-platform-primary-grey-800">
                  Create Project
                </div>
                <button
                  type="button"
                  aria-label="create-project-button"
                  className="bg-white border-platform-cta-error-800 border-2 rounded-md p-2 text-platform-cta-error-800 font-bold hover:bg-platform-cta-error-600 hover:text-platform-cta-error-200 focus:bg-platform-cta-error-800 focus:text-white"
                  onClick={() => setShowModal(false)}>
                  <HiX />
                </button>
              </div>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit, onError)}>
                  <div className="relative p-6 flex-auto">
                    <Form form={form} />
                  </div>
                  <div className="p-6 border-t border-solid border-platform-primary-grey-200 rounded-b">
                    {displayAlert &&
                    duplicateProjectResponse.create.success === 'true' ? (
                      <AlertMessage
                        success
                        message={`Project has been created with id ${duplicateProjectResponse.create.project_id}`}
                        closeAlert={() => setDisplayAlert(false)}
                      />
                    ) : displayAlert ? (
                      <AlertMessage
                        success={false}
                        message="Project creation failed"
                        closeAlert={() => setDisplayAlert(false)}
                      />
                    ) : null}
                    {atamisDisplayAlert &&
                    duplicateProjectResponse?.create?.atamis_workplan_id !==
                      undefined ? (
                      <AlertMessage
                        success
                        message={`Project has been created in Atamis with id ${duplicateProjectResponse?.create?.atamis_workplan_id}`}
                        closeAlert={() => setAtamisDisplayAlert(false)}
                      />
                    ) : atamisDisplayAlert ? (
                      <AlertMessage
                        success={false}
                        message="Project creation failed in Atamis, please contact us at support@adviseinc.co.uk"
                        closeAlert={() => setAtamisDisplayAlert(false)}
                      />
                    ) : null}
                    <div className="flex justify-between pt-2">
                      <button
                        className="mx-1 bg-platform-cta-error-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-error-800 hover:border-platform-cta-error-800 border-1"
                        type="button"
                        onClick={() => handleClearForm()}>
                        Clear Form
                      </button>
                      <button
                        className="mx-1 bg-platform-cta-success-800 text-white text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-success-800 hover:border-platform-cta-success-800 border-1 disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800"
                        type="submit">
                        Create Project
                      </button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </div>
  );
});

export default DuplicateProjectModal;
